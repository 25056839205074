import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      background: transparent;
      outline: 0;

      &:after {
        top: 15px;
        left: 15px;
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = [
    'FLutter 2.8',
    'Python Selenium',
    'Laravel',
    'JavaScript (ES6+)',
    'Node.js',
    'WordPress',
    'IA',
    'DL',
  ];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">Story</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
            My journey as an entrepreneur and experienced Python developer has been a fascinating one. It all started in college when I was inspired to learn programming so that I could play a video game. This drive to explore programming languages led me to work as a freelancer in mobile app development, where I gained valuable experience and eventually transitioned to building software as a service (SaaS) applications.

While working for a data collection company, I met many skilled individuals fascinated by artificial intelligence, which sparked my interest in developing AI solutions for businesses. In 2018, I co-founded my first AI development company, where we gained many investors and clients.
            </p>

            <p>
            Throughout my journey, I have worked with various organizations such as {' '}             </p>
             <p> <a href="https://3wmedia.ma/">3WMEDIA</a> ,
              {' is a leading digital transformation agency that specializes in creating innovative solutions to increase business productivity '}             </p>
              <p>   <a href="https://www.imperium-media.com/"> IMPERIUM MEDIA</a>, 
              {'we are committed to providing cutting-edge digital solutions for our clients '}
              </p>
              <p>
              <a href="https://creadev.ma/"> CREADEV</a>. is a digital marketing consulting agency dedicated to helping businesses succeed online .
              </p>where I have honed my skills in creating digital solutions for businesses. My passion for new technologies and AI development has led me to establish DIGIMPERIAL, the first AI company in Morocco with two business partners.
            

            <p>
            I am also actively engaged on Instagram <a href="https://www.instagram.com/nizarcht/">Nizar chtioui</a>
              {' actively engaged on Instagram'}
              advocating for the importance of digital innovation in all aspects of our lives. Let's connect and discuss how we can utilize these technologies to bring your business to the next level and unlock its full potential. &amp;.
            </p>

            <p>As CEO, I continue to explore the possibilities of AI development and its potential to transform our world:</p>
            . My recent experience working with
          </div>

          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/me.jpg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
